import AlephBet from "alephbet"
import {storage_adapter, lamed_adapter} from "./adapters"
import {
  on_premium_payment_success,
  on_dashboard,
  is_new_customer
} from "./helpers"
import {registered_successfully} from "./goals"
import $ from "cash-dom"

export default () => {
  const cross_selling_atlas_ebook = new AlephBet.Experiment({
    name: "202408 Cross-selling Atlas ebook",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () =>
      window.gon.locale === "en" &&
      ((is_new_customer() && on_dashboard()) || on_premium_payment_success()),
    variants: {
      "1-Checkout-variant": {
        activate: experiment => {
          if (!on_premium_payment_success()) return
          if (!experiment.options.trigger()) return
          $(".ab_test-228-cross-selling-atlas-ebook--checkout-variant")
            .addClass("active")
          $(".ab_test-228-cross-selling-atlas-ebook--original").remove()
        }
      },
      "2-Dashboard-variant": {
        activate: experiment => {
          if (!on_dashboard()) return
          if (!experiment.options.trigger()) return
          $(".ab_test-228-cross-selling-atlas-ebook--dashboard-variant")
            .addClass("active")
          $(".ab_test-228-cross-selling-atlas-ebook--original").remove()
        }
      }
    }
  })

  cross_selling_atlas_ebook.add_goals([
    registered_successfully
  ])
}
